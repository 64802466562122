import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import filters from '@/filters'
import BootstrapVue from 'bootstrap-vue'
import ProductZoomer from 'vue-product-zoomer'
import VueTreeNavigation from 'vue-tree-navigation'
import 'jquery/src/jquery.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRunning, faUndo, faTrophy, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faBaby, faBed, faBox, faCalendar, faCartPlus, faCheck, faCheckCircle, faChild, faClipboardList, faClock, faCreditCard, faEdit, faEnvelope, faFilm, faFootballBall, faGift, faGifts, faHeart, faHome, faHotel, faInfoCircle, faLink, faLock, faMapMarkerAlt, faMoneyCheckAlt, faPlane, faPlaneArrival, faPlaneDeparture, faPlay, faPlus, faQuestionCircle, faRocket, faSave, faSearch, faSearchPlus, faShoppingCart, faSignOutAlt, faSpinner, faTag, faTicketAlt, faTimes, faTruck, faTruckLoading, faUndoAlt, faUser, faUserClock, faUserCog, faUsers, faWrench } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueCompositionAPI from '@vue/composition-api'

library.add(faRunning, faTrophy, faHome, faUndo, faBaby, faFilm, faFootballBall, faPlaneArrival, faPlaneDeparture, faChild, faLink, faTruckLoading, faWrench, faHotel, faUserCog, faUserClock, faSignOutAlt, faEdit, faTag, faSpinner, faQuestionCircle, faShoppingCart, faSave, faClipboardList, faUser, faUsers, faCheck, faCheckCircle, faPlus, faSearch, faPlane, faBox, faCartPlus, faHeart, faSearchPlus, faCalendar, faAngleDown, faAngleUp, faAngleLeft, faAngleRight, faRocket, faMoneyCheckAlt, faGift, faGifts, faClock, faUndoAlt, faTruck, faBed, faTicketAlt, faMapMarkerAlt, faInfoCircle, faLock, faEnvelope, faTimes, faPlay, faCreditCard)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)
Vue.use(BootstrapVue)
Vue.use(ProductZoomer)
Vue.use(VueTreeNavigation)
Vue.use(filters)
Vue.use(VueCompositionAPI)
Sentry.init({
  Vue,
  environment: 'production',
  dsn: 'https://0b1df04f5182425fa281389c10e00ab2@o1057314.ingest.sentry.io/6044024',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [process.env.VUE_SENTRY_ORIGINS],
      logErrors: true,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
