import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import admin from './modules/admin'
import user from './modules/user'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: auth,
    admin: admin,
    user: user,
  }
})
