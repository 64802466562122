import axios from './axios-auth'

const getCard = (code, cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/card/' + code,
      method: 'GET',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve({
          value: resp.data.value,
          currency: resp.data.currency,
          expirationTimestamp: resp.data.expirationTimestamp,
          code: resp.data.code,
          status: resp.data.status,
          referenceOrderID: resp.data.referenceOrderID,
          redemptionInstructions: resp.data.redemptionInstructions,
          redemptionCredentialsJson: resp.data.redemptionCredentialsJson,
          imageURL: resp.data.imageURL
        })
      })
      .catch((err) => {
        console.error(err) // adding this for testing purposes
        reject(err)
      })
  })

const activateCard = (code, cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/card/' + code + '/activate',
      method: 'PUT',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve({
          value: resp.data.value,
          currency: resp.data.currency,
          expirationTimestamp: resp.data.expirationTimestamp,
          code: resp.data.code,
          status: resp.data.status,
          referenceOrderID: resp.data.referenceOrderID,
          redemptionInstructions: resp.data.redemptionInstructions,
          redemptionCredentialsJson: resp.data.redemptionCredentialsJson,
        })
      })
      .catch((err) => {
        reject(err)
      })
  })

export { getCard, activateCard }
