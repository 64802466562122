import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    beforeEnter() {
      window.location.replace("https://www.divvyengagement.com")
    },
  },
  {
    path: '/card/:code(.*)',
    name: 'UserViewCard',
    props: (route) => ({ code: String(route.params.code) }),
    component: () => import('@/views/user/Card.vue'),
  },
  {
    path: '/admin/login',
    name: 'AdminLogin',
    component: () => import('@/views/admin/login/Login.vue'),
  },
  {
    path: '/admin/cards',
    name: 'AdminCardsView',
    component: () => import('@/views/admin/cards/List.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/admin/cards/create',
    name: 'AdminCardsCreate1',
    component: () => import('@/views/admin/cards/Create1.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/admin/cards/create2',
    name: 'AdminCardsCreate2',
    component: () => import('@/views/admin/cards/Create2.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/admin/cards/create3',
    name: 'AdminCardsCreate3',
    component: () => import('@/views/admin/cards/Create3.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/admin/settings',
    name: 'AdminSettings',
    component: () => import('@/views/admin/settings/Settings.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/components/Error404.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters['auth/isAuthenticated']) {
    //TODO uncomment
    // next('/')
    next()
  } else {
    next()
  }
})

export default router
