import axios from './axios-auth'
const controller = new AbortController();

const login = (username, password, cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/auth/login',
      data: {
        username: username,
        password: password,
      },
      method: 'POST',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve(resp.data.jwtToken)
      })
      .catch((err) => {
        reject(err)
      })
  })

const getTemplates = (cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/template',
      method: 'GET',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve(
          resp.data.map(template => (
            {
              id: template.id,
              imageUrl: template.imageUrl,
            }
          )))
      })
      .catch((err) => {
        reject(err)
      })
  })

const getCards = (offset, limit, searchCriteria, cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/admin/card',
      method: 'GET',
      params: {
        offset: offset,
        limit: limit,
        searchCriteria : searchCriteria
      },
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve(
          resp.data.items.map(card => (
            {
              value: card.value,
              currency: card.currency,
              expirationTimestamp: card.expirationTimestamp,
              status: card.status,
              userName: card.userName,
              userEmail: card.userEmail,
              creationTimestamp: card.creationTimestamp,
              code: card.code,
              referenceOrderID: card.referenceOrderID,
              redemptionInstructions: card.redemptionInstructions,
              redemptionCredentialsJson: card.redemptionCredentialsJson,
              redemptionTimestamp: card.redemptionTimestamp,
              orderId: card.orderId
            }
          )))
      })
      .catch((err) => {
        console.error(err)
        reject(err)
      })
  })

const createCards = (cards, cancelToken) =>
  new Promise((resolve, reject) => {
    let theCards = cards.map(card => (
      {
        value: card.value,
        currency: card.currency,
        userName: card.userName,
        userEmail: card.userEmail,
        templateId: card.templateId,
        orderId : card.orderId,
        prepaidCardType: card.prepaidCardType,
      }
    ))
    axios({
      url: '/admin/card',
      data: theCards,
      method: 'POST',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve(resp.data.map(card => (
          {
            value: card.value,
            currency: card.currency,
            expirationTimestamp: card.expirationTimestamp,
            status: card.status,
            userName: card.userName,
            userEmail: card.userEmail,
            creationTimestamp: card.creationTimestamp,
            code: card.code,
            referenceOrderID: card.referenceOrderID,
            redemptionInstructions: card.redemptionInstructions,
            redemptionCredentialsJson: card.redemptionCredentialsJson,
            redemptionTimestamp: card.redemptionTimestamp,
            orderId : card.orderId
          }
        )))
      })
      .catch((err) => {
        reject(err)
      })
  })

const cancelCard = (code) => 
  new Promise((resolve, reject) => {
    axios({
      url: '/admin/card/' + code + '/cancel',
      method: 'PUT',
      signal: controller.signal
    })
      .then((resp) => {
        if (resp.status == 200) {
        resolve({
          value: resp.data.value,
          currency: resp.data.currency,
          expirationTimestamp: resp.data.expirationTimestamp,
          status: resp.data.status,
          userName: resp.data.userName,
          userEmail: resp.data.userEmail,
          creationTimestamp: resp.data.creationTimestamp,
          code: resp.data.code,
          referenceOrderID: resp.data.referenceOrderID,
          redemptionInstructions: resp.data.redemptionInstructions,
          redemptionCredentialsJson: resp.data.redemptionCredentialsJson,
          redemptionTimestamp: resp.data.redemptionTimestamp,
        })
      }
      else {
        reject(`Did not receive 200. Received: ${resp.status}`)
      }
      })
      .catch((err) => {
        reject(err)
      })

      controller.abort();
  })

const emailCardOwner = async (code) => {
  try {
    const resp = await axios({
      url: '/admin/card/' + code + '/email',
      method: 'PUT',
      signal: controller.signal
    })

    return resp

  }
  catch (e) {
    console.error(e)
    throw e;
  }
  finally {
    controller.abort()
  }

}

const changeEmail = async (code, oldEmail, newEmail) => {
  try {
    const resp = await axios({
      url: `/admin/card/${code}/changeEmail`,
      method: 'PUT',
      data:
        { "oldEmail": oldEmail, "newEmail": newEmail }
    })

    return resp
  }
  catch (e) {
    console.error(e)
  }


}
const getSettings = (cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/settings',
      method: 'GET',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve({
          expirationDays: resp.data.expirationDays,
          tangoFundsThreshold: resp.data.tangoFundsThreshold,
          tangoFundsEmail: resp.data.tangoFundsEmail,
        })
      })
      .catch((err) => {
        reject(err)
      })
  })

const putSettings = (expirationDays, tangoFundsThreshold, tangoFundsEmail, cancelToken) =>
  new Promise((resolve, reject) => {
    axios({
      url: '/settings',
      data: {
        expirationDays: expirationDays,
        tangoFundsThreshold: tangoFundsThreshold,
        tangoFundsEmail: tangoFundsEmail,
      },
      method: 'PUT',
      cancelToken: cancelToken
    })
      .then((resp) => {
        resolve({
          expirationDays: resp.data.expirationDays,
          tangoFundsThreshold: resp.data.tangoFundsThreshold,
          tangoFundsEmail: resp.data.tangoFundsEmail,
        })
      })
      .catch((err) => {
        reject(err)
      })
  })

export { changeEmail, login, getTemplates, getCards, createCards, cancelCard, emailCardOwner, getSettings, putSettings }
