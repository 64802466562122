import { cancelCard, createCards, emailCardOwner, getCards, getSettings, getTemplates, putSettings } from '@/api/admin'
import Axios from 'axios'

const axiosSource = {
  createCards: undefined,
  cancelCard: undefined,
  emailCardOwner: undefined,
}

const admin = {
  namespaced: true,
  state: () => ({
    status: {
      templates: {
        initialized: false,
        refreshing: false,
        error: false,
      },
      cards: {
        initialized: false,
        refreshing: false,
        error: false,
      },
      createCards: {
        initialized: false,
        refreshing: false,
        error: false,
      },
      settings: {
        initialized: false,
        refreshing: false,
        error: false,
      },
    },
    templates: [],
    cards: [],
    settings: {
      expirationDays: 0,
      tangoFundsThreshold: 0,
      tangoFundsEmail: '',
    },
    chosenTemplateId: undefined,
    currentCardsPage: 0,
    preparedNewCards: [],
    currency: "USD",
  }
  ),
  getters: {},
  actions: {
    fetchTemplates({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'templates')
        commit('SET_ERROR_FALSE', 'templates')

        getTemplates()
          .then((templates) => {
            commit('SET_INITIALIZED_TRUE', 'templates')
            commit('SET_REFRESHING_FALSE', 'templates')
            commit('SET_TEMPLATES', templates)
            resolve()
          })
          .catch((err) => {
            commit('SET_REFRESHING_FALSE', 'templates')
            commit('SET_ERROR_TRUE', 'templates')
            reject(err)
          })
      })
    },
    fetchCards({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'cards')
        commit('SET_ERROR_FALSE', 'cards')

        const limit = data.limit
        let offset = 0
        if (data.page) {
          offset = data.page * limit
        }
        getCards(offset, limit, data.searchCriteria)
          .then((cards) => {
            commit('SET_INITIALIZED_TRUE', 'cards')
            commit('SET_REFRESHING_FALSE', 'cards')
            commit('SET_CARDS', cards)
            commit('SET_CURRENT_CARDS_PAGE', data.page)
            resolve()
          })
          .catch((err) => {
            commit('SET_REFRESHING_FALSE', 'cards')
            commit('SET_ERROR_TRUE', 'cards')
            reject(err)
          })
      })
    },
    prepareNewCard({ commit }, data) {
      return new Promise((resolve, reject) => {
        const payload = {
          value: data.value,
          userName: data.userName,
          userEmail: data.userEmail,
          prepaidCardType: data.prepaidCardType,
        }
        commit('PREPARE_NEW_CARD', payload)
      })
    },
    prepareNewCards({ commit }, data) {
      commit('PREPARE_NEW_CARDS', data)

    },
    removePreparedNewCard({ commit }, data) {
      return new Promise((resolve, reject) => {
        const payload = {
          item: data.item,
        }
        commit('REMOVE_PREPARED_NEW_CARD', payload)
      })
    },
    emptyPreparedNewCards({ commit }) {
      return new Promise((resolve, reject) => {
        commit('EMPTY_PREPARED_NEW_CARDS')
      })
    },
    createCards({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'createCards')
        commit('SET_ERROR_FALSE', 'createCards')
        if (axiosSource.createCards) {
          axiosSource.createCards.cancel()
        }
        axiosSource.createCards = Axios.CancelToken.source()

        let payload = data.cards.map(card => (
          {
            value: card.value,
            userName: card.userName,
            userEmail: card.userEmail,
            currency: data.currency,
            templateId: data.templateId,
            orderId: card.orderId,
            prepaidCardType: card.prepaidCardType,
          }
        ))

        createCards(payload, axiosSource.createCards.token)
          .then((resp) => {
            commit('SET_INITIALIZED_TRUE', 'createCards')
            commit('SET_REFRESHING_FALSE', 'createCards')
            resolve(resp)
          })
          .catch((err) => {
            commit('SET_REFRESHING_FALSE', 'createCards')
            commit('SET_ERROR_TRUE', 'createCards')
            reject(err)
          })
      })
    },
    cancelCard({
      commit,
      dispatch,
      state
    }, data) {
      return new Promise((resolve, reject) => {
        if (axiosSource.cancelCard) {
          axiosSource.cancelCard.cancel()
        }
        axiosSource.cancelCard = Axios.CancelToken.source()

        cancelCard(data.code, axiosSource.cancelCard.token)
          .then(() => {
            resolve()
            dispatch('fetchCards', { page: state.currentCardsPage })
          })
          .catch((err) => {
            reject(err)
            dispatch('fetchCards', { page: state.currentCardsPage })
          })
      })
    },

    async changeEmail({ commit, dispatch, state }, data) {
      try {
        const resp = await axiosSource.changeEmail(data.code, data.oldEmail, data.newEmail)

        return resp;
      }
      catch (e) {
        console.log(e)
      }

      // refetch the cards to ge tthe new email
      dispatch('fetchCards', { page: state.currentCardsPage })
    },
    emailCardOwner({
      commit,
      dispatch
    }, data) {
      return new Promise((resolve, reject) => {
        if (axiosSource.emailCardOwner) {
          axiosSource.emailCardOwner.cancel()
        }
        axiosSource.emailCardOwner = Axios.CancelToken.source()

        emailCardOwner(data.code, axiosSource.emailCardOwner.token)
          .then(() => {
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    fetchSettings({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'settings')
        commit('SET_ERROR_FALSE', 'settings')

        getSettings()
          .then((settings) => {
            commit('SET_INITIALIZED_TRUE', 'settings')
            commit('SET_REFRESHING_FALSE', 'settings')
            commit('SET_SETTINGS', settings)
            resolve()
          })
          .catch((err) => {
            commit('SET_REFRESHING_FALSE', 'settings')
            commit('SET_ERROR_TRUE', 'settings')
            reject(err)
          })
      })
    },
    putSettings({
      commit,
      dispatch
    }, data) {
      return new Promise((resolve, reject) => {
        putSettings(data.expirationDays, data.tangoFundsThreshold, data.tangoFundsEmail)
          .then(() => {
            resolve()
            dispatch('fetchSettings')
          })
          .catch((err) => {
            reject(err)
            dispatch('fetchSettings')
          })
      })
    },
    setChosenTemplateId({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('SET_CHOSEN_TEMPLATE_ID', data.templateId)
        resolve()
      })
    },
  },
  mutations: {
    SET_INITIALIZED_TRUE: (state, dataName) => {
      state.status[dataName].initialized = true
    },
    SET_REFRESHING_TRUE:
      (state, dataName) => {
        state.status[dataName].refreshing = true
      },
    SET_REFRESHING_FALSE:
      (state, dataName) => {
        state.status[dataName].refreshing = false
      },
    SET_ERROR_TRUE:
      (state, dataName) => {
        state.status[dataName].error = true
      },
    SET_ERROR_FALSE:
      (state, dataName) => {
        state.status[dataName].error = false
      },
    SET_CARDS:
      (state, payload) => {
        state.cards = payload
      },
    SET_TEMPLATES:
      (state, payload) => {
        state.templates = payload
      },
    SET_SETTINGS:
      (state, payload) => {
        state.settings.expirationDays = payload.expirationDays
        state.settings.tangoFundsThreshold = payload.tangoFundsThreshold
        state.settings.tangoFundsEmail = payload.tangoFundsEmail
      },
    SET_CHOSEN_TEMPLATE_ID:
      (state, payload) => {
        state.chosenTemplateId = payload
      },
    SET_CURRENT_CARDS_PAGE:
      (state, payload) => {
        state.currentCardsPage = payload
      },
    PREPARE_NEW_CARD:
      (state, payload) => {
        state.preparedNewCards.push({
          value: payload.value,
          userName: payload.userName,
          userEmail: payload.userEmail,
          prepaidCardType: payload.prepaidCardType,
        })
      },
    PREPARE_NEW_CARDS:
      (state, payload) => {
        state.preparedNewCards.push(...payload)
        console.log(state.preparedNewCards )
      },
    REMOVE_PREPARED_NEW_CARD:
      (state, payload) => {
        state.preparedNewCards = state.preparedNewCards.filter(item => item !== payload.item)
      },
    EMPTY_PREPARED_NEW_CARDS:
      (state) => {
        state.preparedNewCards = []
      },
  },
}

export default admin
