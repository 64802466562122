<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from '@/api/axios-auth'

export default {
  created () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch('auth/logOut')
        }
        throw err
      })
    })
  }
}
</script>

<style lang="scss">
@import './styles/_custom.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>
