import moment from 'moment'

const numToLocaleFilter = num => {
  if (isNaN(num)) {
    return null
  } else {
    return num.toLocaleString()
  }
}

const titleCaseFilter = str => str.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

const formatDateFilter = value => {
  const locale = window.navigator.userLanguage || window.navigator.language // TODO get country as well
  moment()
    .locale(locale)
  return moment(value)
    .format('LL')
}

export default {
  install (Vue) {
    Vue.filter('numToLocale', numToLocaleFilter)
    Vue.filter('titleCase', titleCaseFilter)
    Vue.filter('formatDate', formatDateFilter)
  }
}
