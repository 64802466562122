import { getCard, activateCard } from '@/api/user'
import Axios from 'axios'

const axiosSource = {
  activateCard: undefined,
}

const user = {
  namespaced: true,
  state: () => ({
    status: {
      card: {
        initialized: false,
        refreshing: false,
        error: false,
      },
    },
    card: {
      value: 0,
      currency: 'USD',
      expirationTimestamp: 0,
      code: '',
      status: '',
      referenceOrderID: '',
      redemptionInstructions: '',
      redemptionCredentialsJson: '',
      imageURL: ''
    },
  }),
  getters: {
    isCardPending: (state) => state.card.status === 'PENDING',
    isCardActive: (state) => state.card.status === 'ACTIVE',
    isCardExpired: (state) => state.card.status === 'EXPIRED',
    isCardCancelled: (state) => state.card.status === 'CANCELLED',
  },
  actions: {
    fetchCard ({
      commit,
      dispatch
    }, data) {
      
      return new Promise((resolve, reject) => {
        commit('SET_REFRESHING_TRUE', 'card')
        commit('SET_ERROR_FALSE', 'card')
        getCard(data.code)
          .then((card) => {
            commit('SET_INITIALIZED_TRUE', 'card')
            commit('SET_REFRESHING_FALSE', 'card')
            commit('SET_CARD', card)
            resolve()
          })
          .catch((err) => {
            console.error(err)
            commit('SET_REFRESHING_FALSE', 'card')
            commit('SET_ERROR_TRUE', 'card')
            reject(err)
          })
      })
    },
    activateCard ({
      commit,
      dispatch
    }, data) {
      if (axiosSource.activateCard) {
        axiosSource.activateCard.cancel()
      }
      axiosSource.activateCard = Axios.CancelToken.source()

      return new Promise((resolve, reject) => {
        activateCard(data.code, axiosSource.activateCard.token)
          .then(() => {
            resolve()
            dispatch('fetchCard', { code: data.code })
          })
          .catch((err) => {
            reject(err)
            dispatch('fetchCard', { code: data.code })
          })
      })
    },
  },
  mutations: {
    SET_INITIALIZED_TRUE: (state, dataName) => {
      state.status[dataName].initialized = true
    },
    SET_REFRESHING_TRUE: (state, dataName) => {
      state.status[dataName].refreshing = true
    },
    SET_REFRESHING_FALSE: (state, dataName) => {
      state.status[dataName].refreshing = false
    },
    SET_ERROR_TRUE: (state, dataName) => {
      state.status[dataName].error = true
    },
    SET_ERROR_FALSE: (state, dataName) => {
      state.status[dataName].error = false
    },
    SET_CARD: (state, payload) => {
      console.log(payload)
      state.card.value = payload.value
      state.card.currency = payload.currency
      state.card.code = payload.code
      state.card.status = payload.status
      state.card.referenceOrderID = payload.referenceOrderID
      state.card.redemptionInstructions = payload.redemptionInstructions
      state.card.redemptionCredentialsJson = payload.redemptionCredentialsJson
      state.card.imageURL = payload.imageURL
    },
  },
}

export default user
